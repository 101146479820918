import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';

const Dummy = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f5f5f5',
            }}
        >
            <Card
                sx={{
                    maxWidth: 600,
                    padding: 3,
                    boxShadow: 3,
                    backgroundColor: '#ffffff',
                }}
            >
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Error: Unable to Load Module
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We encountered an issue while trying to load the module.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Please ensure that the required module is installed and that you have the correct version.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Error Details: Unable to load module 'traceroute'. Version 2.1.4 or later is required.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Suggested Actions:
                        <ul>
                            <li>Check your module installation.</li>
                            <li>Verify the module version.</li>
                            <li>Ensure compatibility with your environment.</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If the issue persists, please contact support with the details provided above.
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Dummy;
