// src/components/Home.js
import React from 'react';
import { Container } from '@mui/material';
import useAuth from '../hooks/useAuth';
import { getUserType } from '../utils/tokenUtils';
import UserView from './UserHomeView';
import AgentView from './AgentHomeView';



const Home = () => {
    useAuth(); // Ensure authentication is handled

    const userType = getUserType(); // Get user type from token or authentication context

    return (
        <Container maxWidth="lg" style={{  margin: '0' }}>
            {userType === 'agent' ? <AgentView /> : <UserView />}
        </Container>
    );
};

export default Home;
