import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Paper } from '@mui/material';
import BusinessFormationForm from './OrganizerTabs/BusinessFormationForm';
import BasicDetailsForm from './OrganizerTabs/BasicDetailsForm';
import DependentsForm from './OrganizerTabs/DependentsForm';
import StateDetailsForm from './OrganizerTabs/StateDetailsForm';
import IncomesForm from './OrganizerTabs/IncomesForm';
import RentalForm from './OrganizerTabs/RentalForm';
import ExpensesForm from './OrganizerTabs/ExpensesForm';
import EntityInformationForm from './OrganizerTabs/EntityInformationForm';
import PartnerDetailsForm from './OrganizerTabs/PartnerDetailsForm';
import BalanceSheetForm from './OrganizerTabs/BalanceSheetForm';
import HomeOfficeExpenses from './OrganizerTabs/HomeOfficeExpenses';
import Dummy from '../dummy';


const apiUrl = process.env.REACT_APP_API_URL;

const TaxOrganizerTab = ({ ticketData, setTicketData, userType }) => {
    const [subTabValue, setSubTabValue] = useState(0);

    const handleSubTabChange = (event, newValue) => {
        setSubTabValue(newValue);
    };

    const getTabs = (serviceName) => {
        if (serviceName === "Business Formation or Incorporation") {
            return ["Business Formation Form"];
        } else if (["Individual Tax Filing Form 1040's", "Individual Amendment Filing"].includes(serviceName)) {
            return [
                "Basic Details",
                "Dependents",
                "State Details",
                "Incomes",
                "Rental",
                "Expenses"
            ];
        } else if ([
            "Business Tax Filing",
            "Business Amendment Filing",
            "Audit representation",
            "Bookkeeping and Accounting",
            "Payroll Services"
        ].includes(serviceName)) {
            return [
                "Entity Information",
                "Partners/Members/ Shareholders Information",
                "Balance Sheet",
                "Home Office Expenses"
            ];
        } else {
            return [];
        }
    };

    const tabs = getTabs(ticketData.service_name);

    return (
        <Box>
            <Typography variant="h6">Tax Organizer</Typography>

            <Box sx={{ display: 'flex', mt: 2 }}>
                <Tabs
                    orientation="vertical"
                    value={subTabValue}
                    onChange={handleSubTabChange}
                    sx={{ borderRight: 1, borderColor: 'divider', width: '200px' }}
                >
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab} />
                    ))}
                </Tabs>

               {/* <Paper sx={{ flex: 1, p: 2 }}>
                    {tabs.map((tab, index) => (
                        subTabValue === index && (
                            {
                                "Business Formation Form": <BusinessFormationForm key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData} />,
                                "Basic Details": <BasicDetailsForm key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Dependents": <DependentsForm key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "State Details": <StateDetailsForm key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Incomes": <IncomesForm key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData} />,
                                "Rental": <RentalForm key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Expenses": <ExpensesForm key={index} />,
                                "Entity Information": <EntityInformationForm key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Partners/Members/ Shareholders Information": <PartnerDetailsForm key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Balance Sheet": <BalanceSheetForm key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Home Office Expenses": <HomeOfficeExpenses key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>
                            }[tab]
                        )
                    ))}
                </Paper> */}

<Paper sx={{ flex: 1, p: 2 }}>
                    {tabs.map((tab, index) => (
                        subTabValue === index && (
                            {
                                "Business Formation Form": <BusinessFormationForm key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData} />,
                                "Basic Details": <Dummy key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Dependents": <Dummy key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "State Details": <Dummy key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Incomes": <Dummy key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData} />,
                                "Rental": <Dummy key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Expenses": <Dummy key={index} />,
                                "Entity Information": <EntityInformationForm key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Partners/Members/ Shareholders Information": <Dummy key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Balance Sheet": <Dummy key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>,
                                "Home Office Expenses": <HomeOfficeExpenses key={index} ticketData={ticketData} userType={userType} setTicketData={setTicketData}/>
                            }[tab]
                        )
                    ))}
                </Paper> 
            </Box>
        </Box>
    );
};

export default TaxOrganizerTab;