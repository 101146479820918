import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import axios from 'axios';
import { getValidAccessToken } from '../../utils/tokenUtils';

const apiUrl = process.env.REACT_APP_API_URL;


const MessagesTab = ({ ticketId, userType, ticketData }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const messagesEndRef = useRef(null);

    useEffect(() => {
        const fetchMessages = async () => {
            const token = await getValidAccessToken();
            try {
                const response = await axios.get(`${apiUrl}/tickets/messages/?ticket_id=${ticketId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (response.data.success) {
                    setMessages(response.data.data);
                    scrollToBottom();
                } else {
                    setErrorMessage('Unable to fetch messages.');
                }
            } catch (error) {
                setErrorMessage('Error fetching messages.');
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, [ticketId]);

    const handleSendMessage = async (messageType = "Public") => {
        if (!newMessage.trim()) return; // Prevent sending empty messages

        const token = await getValidAccessToken();
        try {
            const response = await axios.post(`${apiUrl}/tickets/messages/`, {
                ticket_id: ticketId,
                message_content: newMessage,
                sender_type: userType,
                message_type: messageType
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.success) {
                setMessages([...messages, response.data.data]);
                setNewMessage('');
                scrollToBottom();
            } else {
                setErrorMessage('Unable to send message.');
            }
        } catch (error) {
            setErrorMessage('Error sending message.');
            console.error('Error sending message:', error);
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage("Public");
        }
    };

    return (
        <Box>
            {errorMessage && <Typography variant="body2" color="error">{errorMessage}</Typography>}
            
            {userType === 'agent' && ticketData && ticketData.reason && (
                <Box sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: 1, backgroundColor: '#f5f5f5' }}>
                    <Typography variant="body2" color="textSecondary">
                        <strong>Reason:</strong> {ticketData.reason}
                    </Typography>
                </Box>
            )}
            
            <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 1, mb: 2, maxHeight: '400px', overflowY: 'auto' }}>
                {messages.length > 0 ? (
                    messages.map((message) => (
                        <Box key={message.ticket_message_id} sx={{ mb: 1 }}>
                            {userType === 'agent' && (
                                <Box sx={{ display: 'flex', justifyContent: (message.sender_type === 'user' ? 'flex-start' : 'flex-end') }}>
                                    <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                                        {message.sender_type === 'user' ? message.username : message.agentname}
                                    </Typography>
                                </Box>
                            )}
                            <Box sx={{ display: 'flex', justifyContent: (userType === 'user' ? (message.sender_type === 'user' ? 'flex-end' : 'flex-start') : (message.sender_type === 'user' ? 'flex-start' : 'flex-end')) }}>
                                <Typography variant="body2" sx={{ maxWidth: '70%', wordWrap: 'break-word', p: 1, backgroundColor: message.sender_type === 'user' ? '#d1ecf1' : '#f8d7da', borderRadius: 1 }}>
                                    {message.message_content}
                                    {message.message_type === "Private" && (
                                        <Typography variant="caption" sx={{ display: 'block', fontStyle: 'italic', fontSize: '0.75rem', color: '#ff5722', fontWeight: 'bold' }}>
                                            (Sent Internally)
                                        </Typography>
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    ))
                ) : <Typography variant="body2">No messages found.</Typography>}
                <div ref={messagesEndRef} />
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type your message..."
                    inputProps={{ style: { fontSize: '16px' } }}
                />
                <Button variant="contained" onClick={() => handleSendMessage("Public")}>Send</Button>
                {userType === 'agent' && <Button variant="contained" onClick={() => handleSendMessage("Private")}>Send Internally</Button>}
            </Box>
        </Box>
    );
};

export default MessagesTab;
