import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Grid, TextField, Radio, RadioGroup, FormControlLabel,Typography } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';

const apiUrl = process.env.REACT_APP_API_URL;


const EntityInformationForm = ({ userType, ticketData, setTicketData }) => {
    const [entityDetails, setEntityDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [isVerified, setIsVerified] = useState(ticketData?.organizer_verified);

    useEffect(() => {
        const fetchEntityDetails = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'entity_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.entity_details;
                if (details) {
                    setEntityDetails(JSON.parse(details));
                }
            } catch (error) {
                console.error('Error fetching entity formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchEntityDetails();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        try {
            const token = await getValidAccessToken();
            await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'entity_details',
                    column_value: JSON.stringify(entityDetails),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log('Form saved successfully');
        } catch (error) {
            console.error('Error saving form:', error);
        }
    };

    const handleVerify = async () => {
        try {
            const token = await getValidAccessToken();
            const response = await axios.get(
                `${apiUrl}/api/verify-document/?ticket_id=${ticketData?.ticket_id}&column=organizer`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.data.success) {
                setIsVerified(true);
                setTicketData({
                    ...ticketData,
                    organizer_verified: true,
                    organizer_verified_by: 'Agent Name',
                });
            }
        } catch (error) {
            console.error('Error verifying document:', error);
        }
    };

    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        const ticketNumber = ticketData?.ticket_id || 'unknown';
        const filename = `Entity_Details_${ticketNumber}.pdf`;

        let y = 10; // Start position for text in PDF

        doc.setFontSize(12);

        // Loop through the entity details and add to PDF
        Object.keys(entityDetails).forEach((key) => {
            const label = key.replace(/_/g, ' ').toUpperCase();
            const value = entityDetails[key] || '';
            doc.text(`${label}: ${value}`, 10, y);
            y += 10;
        });

        doc.save(filename);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEntityDetails({ ...entityDetails, [name]: value });
    };

    const renderFormFields = () => (
        <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
                {/* Line 1: Entity Type, Entity Name */}
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        value={entityDetails.entity_type || ''}
                        onChange={(e) => setEntityDetails({ ...entityDetails, entity_type: e.target.value })}
                        disabled={userType === 'agent' || isVerified}
                    >
                        <FormControlLabel value="Multi Member LLC" control={<Radio />} label="Multi Member LLC" />
                        <FormControlLabel value="Partnership/S - Corporation" control={<Radio />} label="Partnership/S - Corporation" />
                        <FormControlLabel value="Corporation" control={<Radio />} label="Corporation" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Entity Name"
                        name="entity_name"
                        value={entityDetails.entity_name || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>

                {/* Line 2: DBA, Business Code, EIN */}
                <Grid item xs={4}>
                    <TextField
                        label="DBA"
                        name="dba"
                        value={entityDetails.dba || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Business Code"
                        name="business_code"
                        value={entityDetails.business_code || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="EIN"
                        name="ein"
                        value={entityDetails.ein || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>

                {/* Line 3: State Register Number, Business Profession, Product, or Service */}
                <Grid item xs={6}>
                    <TextField
                        label="State Register Number"
                        name="state_register_number"
                        value={entityDetails.state_register_number || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Business Profession, Product, or Service"
                        name="profession_service"
                        value={entityDetails.profession_service || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>

                {/* Line 4: Address */}
                <Grid item xs={3}>
                    <TextField
                        label="Street Address"
                        name="street_address"
                        value={entityDetails.street_address || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="City"
                        name="city"
                        value={entityDetails.city || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="State"
                        name="state"
                        value={entityDetails.state || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Zip Code"
                        name="zip_code"
                        value={entityDetails.zip_code || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>

                {/* Line 5: Phone Number, Email */}
                <Grid item xs={6}>
                    <TextField
                        label="Business Phone Number"
                        name="phone_number"
                        value={entityDetails.phone_number || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Business Email Address"
                        name="email_address"
                        value={entityDetails.email_address || ''}
                        onChange={handleInputChange}
                        disabled={userType === 'agent' || isVerified}
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Box mt={2}>
                {userType === 'user' && (
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {entityDetails ? 'Update' : 'Submit'}
                    </Button>
                )}
            </Box>
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                {userType === 'agent' && isVerified && (
                    <Button variant="contained" color="secondary" disabled>
                        Verified
                    </Button>
                )}
                {userType === 'agent' && !isVerified && (
                    <Button variant="contained" color="primary" onClick={handleVerify}>
                        Verify
                    </Button>
                )}
                {userType === 'agent' && (
                    <Button variant="contained" color="primary" onClick={handleDownloadPdf} style={{ marginLeft: 10 }}>
                        Download PDF
                    </Button>
                )}
            </Box>
            {renderFormFields()}
        </Box>
    );
};

export default EntityInformationForm;
