import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import { getValidAccessToken } from '../../utils/tokenUtils';



const apiUrl = process.env.REACT_APP_API_URL;


const initializeInvoice = () => ({
    date: new Date().toLocaleDateString(),
    items: [{ description: '', rate: 0, quantity: 0 }],
    notes: '',
    discount: 0
});

const Invoice = ({ userType, ticketData, setTicketData }) => {
    const isInvoiceEmpty = !ticketData.invoice || Object.keys(ticketData.invoice).length === 0;
    const [invoiceData, setInvoiceData] = useState(isInvoiceEmpty ? initializeInvoice() : ticketData.invoice);
    const [isCreating, setIsCreating] = useState(!ticketData.invoice);
    const [discount, setDiscount] = useState(ticketData.invoice );

    const handleInputChange = (field, value) => {
        setInvoiceData({ ...invoiceData, [field]: value });
    };

    const handleItemChange = (index, field, value) => {
        const newItems = [...invoiceData.items];
        newItems[index][field] = value;
        setInvoiceData({ ...invoiceData, items: newItems });
    };

    const addItem = () => {
        setInvoiceData({ ...invoiceData, items: [...invoiceData.items, { description: '', rate: 0, quantity: 0 }] });
    };

    const calculateSubtotal = () => {
        if (!invoiceData || !invoiceData.items) return 0;
        return invoiceData.items.reduce((acc, item) => acc + item.rate * item.quantity, 0);
    };

    const calculateTotal = (subtotal) => {
        return subtotal - discount;
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
    
        // Add basic text
        doc.text('Invoice', 10, 10);
        doc.text(`Invoice Number: ${ticketData.invoice_id || 'N/A'}`, 10, 20);
        doc.text(`Date: ${invoiceData.date}`, 10, 30);
        doc.text('From: Dummy Address', 10, 40);
        doc.text('To: Dummy Address', 10, 50);
    
        // Define table columns and rows
        const columns = [
            { title: 'Description', dataKey: 'description' },
            { title: 'Rate', dataKey: 'rate' },
            { title: 'Quantity', dataKey: 'quantity' },
            { title: 'Amount', dataKey: 'amount' }
        ];
    
        const rows = invoiceData.items.map(item => ({
            description: item.description,
            rate: `$${item.rate.toFixed(2)}`,
            quantity: item.quantity,
            amount: `$${(item.rate * item.quantity).toFixed(2)}`
        }));
    
        // Add table to PDF
        doc.autoTable({
            columns: columns,
            body: rows,
            startY: 60, // Start position of the table
            margin: { horizontal: 10 }
        });
    
        // Add subtotal, discount, and total
        const subtotal = calculateSubtotal();
        const total = calculateTotal(subtotal);
        doc.text(`Subtotal: $${subtotal.toFixed(2)}`, 10, doc.previousAutoTable.finalY + 10);
        doc.text(`Discount: $${discount.toFixed(2)}`, 10, doc.previousAutoTable.finalY + 20);
        doc.text(`Total: $${total.toFixed(2)}`, 10, doc.previousAutoTable.finalY + 30);
        doc.text(`Notes: ${invoiceData.notes}`, 10, doc.previousAutoTable.finalY + 40);
    
        // Save PDF
        doc.save('invoice.pdf');
    };
    

    const handleCreateInvoice = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.post(`${apiUrl}/ticket/create-invoice/`, {
                ticket_id: ticketData.ticket_id
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                console.log('Invoice created successfully');
                setTicketData(response.data.data); // Update ticketData with the response
                setInvoiceData({ ...initializeInvoice(), discount: 0 }); // Initialize invoice data with default values
                setIsCreating(false);
            } else {
                console.error('Error creating invoice');
            }
        } catch (error) {
            console.error('Error creating invoice:', error);
        }
    };

    const handleSubmit = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.post(`${apiUrl}/ticket/update-invoice/`, {
                ticket_id: ticketData.ticket_id,
                invoice: { ...invoiceData, discount }
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                console.log('Invoice updated successfully');
                setTicketData(response.data.data); // Update ticketData with the response
            } else {
                console.error('Error updating invoice');
            }
        } catch (error) {
            console.error('Error updating invoice:', error);
        }
    };

    const subtotal = calculateSubtotal();
    const total = calculateTotal(subtotal);

    return (
        <Box sx={{ p: 3 }}>
            {(isInvoiceEmpty && !isCreating) ? (
                userType === 'agent' ? (
                    <Button variant="contained" onClick={handleCreateInvoice}>
                        Create Invoice
                    </Button>
                ) : (
                    <Typography>No invoice generated</Typography>
                )
            ) : (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                        <img src="dummy-logo.png" alt="Logo" style={{ width: 100 }} />
                        <Box>
                            <Typography variant="h6">Invoice</Typography>
                            <Typography>Invoice Number: {ticketData.invoice_id || 'N/A'}</Typography>
                            <Typography>Date: {invoiceData.date}</Typography>
                            <Typography>From: Dummy Address</Typography>
                            <Typography>To: Dummy Address</Typography>
                        </Box>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Rate</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoiceData.items.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {userType === 'agent' ? (
                                                <TextField
                                                    value={item.description}
                                                    onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                                                    fullWidth
                                                    size="small"
                                                />
                                            ) : (
                                                <Typography>{item.description}</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {userType === 'agent' ? (
                                                <TextField
                                                    type="number"
                                                    value={item.rate}
                                                    onChange={(e) => handleItemChange(index, 'rate', parseFloat(e.target.value))}
                                                    fullWidth
                                                    size="small"
                                                />
                                            ) : (
                                                <Typography>${item.rate.toFixed(2)}</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {userType === 'agent' ? (
                                                <TextField
                                                    type="number"
                                                    value={item.quantity}
                                                    onChange={(e) => handleItemChange(index, 'quantity', parseInt(e.target.value))}
                                                    fullWidth
                                                    size="small"
                                                />
                                            ) : (
                                                <Typography>{item.quantity}</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>${(item.rate * item.quantity).toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {userType === 'agent' && (
                        <Button onClick={addItem} sx={{ mt: 2 }}>Add Item</Button>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                        <Box sx={{ flexGrow: 1, mr: 2 }}>
                            {userType === 'agent' ? (
                                <TextField
                                    label="Notes"
                                    multiline
                                    rows={4}
                                    value={invoiceData.notes}
                                    onChange={(e) => handleInputChange('notes', e.target.value)}
                                    fullWidth
                                />
                            ) : (
                                <Typography>Notes: {invoiceData.notes}</Typography>
                            )}
                        </Box>
                        <Box>
                            <Typography>Subtotal: ${subtotal.toFixed(2)}</Typography>
                            {userType === 'agent' && (
                                <TextField
                                    label="Discount"
                                    type="number"
                                    value={discount}
                                    onChange={(e) => setDiscount(parseFloat(e.target.value))}
                                    sx={{ mb: 2 }}
                                    fullWidth
                                    size="small"
                                />
                            )}
                            {discount > 0 && (
                                <Typography>Discount: ${discount.toFixed(2)}</Typography>
                            )}
                            <Typography>Total: ${total.toFixed(2)}</Typography>
                        </Box>
                    </Box>

                    {userType === 'agent' && (
                        <Button onClick={handleSubmit} variant="contained" sx={{ mt: 3 }}>
                            Submit
                        </Button>
                    )}

                    {userType === 'user' && (
                        <Button onClick={downloadPDF} variant="contained" sx={{ mt: 3 }}>
                            Download PDF
                        </Button>
                    )}
                </>
            )}
        </Box>
    );
};

export default Invoice;
