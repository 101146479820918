// src/components/CreateTicket.js
import React, { useEffect, useState } from 'react';
import {
    Container,
    TextField,
    Button,
    Typography,
    Alert,
    Autocomplete,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth'; // Import the custom hook
import {getValidAccessToken} from '../utils/tokenUtils'

const apiUrl = process.env.REACT_APP_API_URL;


const CreateTicket = () => {
    useAuth();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        selectedTaxYear: null,
        selectedService: null,
        selectedEntity: null,
        additionalDetails: '',
    });

    const [taxYears, setTaxYears] = useState([]);
    const [services, setServices] = useState([]);
    const [entities, setEntities] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isEntityRequired, setIsEntityRequired] = useState(false);

    useEffect(() => {
        const fetchTaxYears = async () => {
            const token = await getValidAccessToken();
            const response = await axios.get(`${apiUrl}/year-dropdown/`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setTaxYears(response.data);
        };

        const fetchServices = async () => {
            const token = await getValidAccessToken();
            const response = await axios.get(`${apiUrl}/services/`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setServices(response.data);
        };

        fetchTaxYears();
        fetchServices();
    }, []);

    const handleChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value,
        });

        // Handle service selection to determine if entity is required
        if (field === 'selectedService') {
            const selectedService = services.find(service => service.service_id === value.service_id);
            setIsEntityRequired(selectedService.is_entity_required);

            if (selectedService.is_entity_required) {
                fetchEntities();
            } else {
                setEntities([]);
            }
        }
    };

    const fetchEntities = async () => {
        const token = await getValidAccessToken();
        const response = await axios.get(`${apiUrl}/entities/`, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        setEntities(response.data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        const ticketData = {
            service_id: formData.selectedService?.service_id,
            service_name: formData.selectedService?.service_name,
            service_year_value: formData.selectedTaxYear?.service_year_value,
            reason: formData.additionalDetails,
            entity_value: formData.selectedEntity?.entity_id || undefined, // Optional
            additional_details: formData.additionalDetails || undefined, // Optional
        };

        try {
            const token = await getValidAccessToken();
            const response = await axios.post(`${apiUrl}/create-ticket/`, ticketData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            if (response.data.success) {
                setSuccessMessage('Ticket Created successfully');
                setTimeout(() => navigate('/home'), 2000); // Redirect after 2 seconds
            } else {
                setErrorMessage('Something went wrong. Unable to create ticket.');
            }
        } catch (error) {
            setErrorMessage('Something went wrong. Unable to create ticket.');
            console.error('Error creating ticket:', error);
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h6" align="center" gutterBottom>
                
            </Typography>
            <Typography variant="h4" align="center" gutterBottom>
                Create a New Ticket
            </Typography>

            {errorMessage && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {errorMessage}
                </Alert>
            )}
            {successMessage && (
                <Alert severity="success" sx={{ mb: 2 }}>
                    {successMessage}
                </Alert>
            )}

            <form onSubmit={handleSubmit}>
                <Autocomplete
                    fullWidth
                    margin="normal"
                    options={taxYears}
                    getOptionLabel={(option) => option.service_year_value.toString()}
                    value={formData.selectedTaxYear}
                    onChange={(_, value) => handleChange('selectedTaxYear', value)}
                    renderInput={(params) => (
                        <TextField {...params} label="Tax Year" variant="outlined" required inputProps={{
                            ...params.inputProps,
                            style: { fontSize: '16px' }, // Increase font size for dropdown
                        }}/>
                        
                    )}
                    sx={{ mb: 2 }} // Add margin bottom for spacing
                />

                <Autocomplete
                    fullWidth
                    margin="normal"
                    options={services}
                    getOptionLabel={(option) => option.service_name}
                    value={formData.selectedService}
                    onChange={(_, value) => handleChange('selectedService', value)}
                    renderInput={(params) => (
                        <TextField {...params} label="Tax Service" variant="outlined" required inputProps={{
                            ...params.inputProps,
                            style: { fontSize: '16px' }, // Increase font size for dropdown
                        }}/>
                    )}
                    sx={{ mb: 2 }} // Add margin bottom for spacing
                />

                {isEntityRequired && (
                    <Autocomplete
                        fullWidth
                        margin="normal"
                        options={entities}
                        getOptionLabel={(option) => option.entity_value}
                        value={formData.selectedEntity}
                        onChange={(_, value) => handleChange('selectedEntity', value)}
                        renderInput={(params) => (
                            <TextField {...params} label="Entity" variant="outlined" required inputProps={{
                                ...params.inputProps,
                                style: { fontSize: '16px' }, // Increase font size for dropdown
                            }}/>
                        )}
                        sx={{ mb: 2 }} // Add margin bottom for spacing
                    />
                )}

                <TextField
                    fullWidth
                    margin="normal"
                    label="Additional Details"
                    variant="outlined"
                    name="additionalDetails"
                    value={formData.additionalDetails}
                    onChange={(e) => handleChange('additionalDetails', e.target.value)}
                    multiline
                    rows={4} // Adjust the number of rows for the text area
                    sx={{ mb: 2 }} // Add margin bottom for spacing
                    inputProps={{ style: { fontSize: '16px' } }}
                />

                <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 3 }}>
                    Submit
                </Button>
            </form>
        </Container>
    );
};

export default CreateTicket;