// src/components/UserView.js
import React, { useEffect, useState } from 'react';
import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Box } from '@mui/material';
import axios from 'axios';
import { getValidAccessToken, handleLogout } from '../utils/tokenUtils';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;


const UserHomeView = () => {
    const [tickets, setTickets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Number of tickets to display per page
    const [totalPages, setTotalPages] = useState(0);
    const [activationStatus, setActivationStatus] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const token = await getValidAccessToken();
            if (token) {
                try {
                    const activationResponse = await axios.get(`${apiUrl}/check-activation-status/`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    setActivationStatus(activationResponse.data);

                    const ticketsResponse = await axios.get(`${apiUrl}/user-tickets/`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    setTickets(ticketsResponse.data);
                    setTotalPages(Math.ceil(ticketsResponse.data.length / itemsPerPage));
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } else {
                handleLogout();
            }
        };

        fetchData();
    }, [currentPage]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleCreateTicket = () => {
        navigate('/create-ticket');
    };

    const handleTicketClick = (ticketId) => {
        navigate(`/ticket-data?ticket_id=${ticketId}`);
    };

    const indexOfLastTicket = currentPage * itemsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - itemsPerPage;
    const currentTickets = tickets.slice(indexOfFirstTicket, indexOfLastTicket);

    return (
        <Box style={{ width: '100%', padding: '20px' }}>
            {activationStatus && !activationStatus.success && (
                <Typography variant="body1" color="error" align="center" gutterBottom>
                    Your account is not yet activated. Please complete it by clicking on the link sent via email.
                </Typography>
            )}

            <Typography variant="h4" align="left" gutterBottom>
                Welcome!
            </Typography>

            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="20px">
                <Typography variant="h5" align="left">
                    My Tickets
                </Typography>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleCreateTicket}
                >
                    Create Ticket
                </Button>
            </Box>

            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold',fontSize: '0.875rem'  }}>Ticket ID</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Tax Year</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Service Name</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Status</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Created On</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentTickets && currentTickets.length > 0 ? (
                            currentTickets.map(ticket => (
                                <TableRow key={ticket.ticket_id} onClick={() => handleTicketClick(ticket.ticket_id)} style={{ cursor: 'pointer' }}>
                                    <TableCell>
                                        <a href="#" onClick={(e) => { e.preventDefault(); handleTicketClick(ticket.ticket_id); }}>
                                            {ticket.ticket_id}
                                        </a>
                                    </TableCell>
                                    <TableCell>{ticket.service_year_value}</TableCell>
                                    <TableCell>{ticket.service_name}</TableCell>
                                    <TableCell>
                                        <Typography variant="body3" sx={{
                                            backgroundColor: '#ff4700',
                                            color: 'white',
                                            borderRadius: '16px',
                                            px: 1,
                                            py: 0.5,
                                            fontSize: '14px',
                                            top: '1px',
                                            fontWeight: 'bold'
                                        }}>
                                            {ticket.ticket_status || 'N/A'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>    {ticket.created_at.replace('T', ' ').split('.')[0]}

                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    No tickets available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
            />
        </Box>
    );
};

export default UserHomeView;
