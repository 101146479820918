import React, { useEffect, useState } from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Tabs, Tab, Button } from '@mui/material';
import axios from 'axios';
import { getValidAccessToken, handleLogout } from '../utils/tokenUtils';
import { useNavigate } from 'react-router-dom';
import  GlobalMessages from '../components/GlobalMessages';
import GlobalNotifications from './GlobalNotifications';
import Dummy from './dummy';

const apiUrl = process.env.REACT_APP_API_URL;


const statusTabs = [
    "Notification", "Messages", "Search", "Customers", "Pending Clients",
    "Express","Upfront Review", "Pending Info", "Under Tax Preparation", "Need more info",
    "Draft Sent", "Draft Rejected", "Draft Approved", "Payment made",
    "E-filing", "8879-sent", "8879-received", "e-filing complete",
    "ITIN", "ITIN Applied", "ITIN Received", "State Return Filed",
    "Paper Filing", "Final Copies Sent", "Closed", "FBAR",
    "FBAR Questionnaire sent", "FBAR Questionnaire Received", "FBAR Sent",
    "FBAR Closed", "BOIR", "BOIR Questionnaire Sent", "BOIR Questionnaire Received",
    "BOIR Sent", "BOIR Filed", "Walkout"
];

const AgentHomeView = () => {
    const [tickets, setTickets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(statusTabs[0]);
    const [statusCounts, setStatusCounts] = useState({}); // For storing ticket counts per status
    const navigate = useNavigate();

    const [unReadCount, setUnReadCount] = useState(0);
    const [noOfCustomers, setNoOfCustomers] = useState(0);

    const [noOfPendingCustomers, setNoOfPendingCustomers] = useState(0);
    const [notificationCount, setNotificationCount] = useState(0);

    

    const updateUnReadCount = (newCount) => {
        setUnReadCount(newCount);
    };

    const updateUnReadNotificationCount = (newCount) => {
        setNotificationCount(newCount);
    };


    useEffect(() => {
        const fetchData = async () => {
            const token = await getValidAccessToken();
            if (token) {
                try {
                    // Fetch count of tickets for each status
                    const countPromises = statusTabs.map(status => 
                        axios.get(`${apiUrl}/tickets/count/?ticket_status=${status}`, {
                            headers: { 'Authorization': `Bearer ${token}` }
                        }).then(response => {
                            console.log(`Count for ${status}:`, response.data); // Log response to verify
                            return {
                                status,
                                count: response.data.data.count // Adjust based on actual API response structure
                            };
                        })
                    );
                    const counts = await Promise.all(countPromises);
                    const countsMap = counts.reduce((acc, { status, count }) => {
                        acc[status] = count;
                        return acc;
                    }, {});
                    setStatusCounts(countsMap);

                    // Fetch tickets for the selected status
                    const ticketsResponse = await axios.get(`${apiUrl}/tickets/?ticket_status=${selectedStatus}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    setTickets(ticketsResponse.data.data);
                    setTotalPages(Math.ceil(ticketsResponse.data.length / itemsPerPage));
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } else {
                handleLogout();
            }
        };

        fetchData();
    }, [currentPage, selectedStatus]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleTicketClick = (ticketId) => {
        navigate(`/ticket-data?ticket_id=${ticketId}`);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedStatus(newValue);
        setCurrentPage(1); // Reset to first page when tab changes
    };

    const indexOfLastTicket = currentPage * itemsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - itemsPerPage;
    const currentTickets = Array.isArray(tickets) ? tickets.slice(indexOfFirstTicket, indexOfLastTicket) : [];

    return (
        <Box style={{ display: 'flex', padding: '10px' }}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '220px',
                    paddingRight: '20px',
                    borderRight: '1px solid #ddd'
                }}
            >
                <Tabs
                    orientation="vertical"
                    value={selectedStatus}
                    onChange={handleTabChange}
                    aria-label="Vertical tabs"
                    variant="scrollable"
                >
                    {statusTabs.map(status => (
                        <Tab
                            key={status}
                            value={status}
                            label={
                                <Box style={{ display: 'flex', alignItems: 'center'}}>
                                    <Typography style={{ fontSize: '11px', flexGrow: 1 }}>{status}</Typography>
                                    {status !== 'Search' && (
    (() => {
        if ((status === 'Messages' && unReadCount === 0) || (status === 'Notification' && notificationCount === 0)) {
            return null; // Do not render Typography if conditions are met
        }
        return (
            <Typography
                style={{
                    fontSize: '11px',
                    backgroundColor: selectedStatus === status ? '#0e1422' : '#f1f1f1',
                    borderRadius: '12px',
                    padding: '2px 6px',
                    marginLeft: '8px'
                }}
            >
                {(() => {
                    if (status === 'Messages') {
                        return unReadCount; // Display unReadCount if status is "Messages"
                    } else if (status === 'Notification') {
                        return notificationCount; // Display noOfCustomers if status is "Customers"
                    } else if (status === 'Customers') {
                        return noOfCustomers; // Display noOfCustomers if status is "Customers"
                    } else if (status === 'Pending Clients') {
                        return noOfPendingCustomers; // Display noOfPendingCustomers if status is "Pending Clients"
                    } else {
                        return statusCounts[status] || 0; // Default case
                    }
                })()}
            </Typography>
        );
    })()
)}
                                </Box>
                            }
                            sx={{ minHeight: '30px', height: '30px' }}
                            style={{
                                fontWeight: selectedStatus === status ? 'bold' : 'normal',
                                backgroundColor: selectedStatus === status ? '#ff4700' : 'transparent',
                                color: selectedStatus === status ? 'white' : 'black',
                                borderRadius: selectedStatus === status ? '4px' : '0',
                                marginBottom: '0px'
                            }}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box style={{ flexGrow: 1, padding: '5px' }}>
    {selectedStatus === "Messages" ? (
        <GlobalMessages updateUnReadCount={updateUnReadCount} />
    ) : selectedStatus === "Notification" ? (
        <GlobalNotifications updateUnReadNotificationCount={updateUnReadNotificationCount} />
    ) : selectedStatus === "Search" ? (
        <Dummy updateUnReadNotificationCount={updateUnReadNotificationCount} />
    ): selectedStatus === "Customers" ? (
        <Dummy updateUnReadNotificationCount={updateUnReadNotificationCount} />
    ):  selectedStatus === "Pending Clients" ? (
        <Dummy updateUnReadNotificationCount={updateUnReadNotificationCount} />
    ): (
        <>
            <TableContainer component={Paper} style={{ marginTop: '0px' }}>
                <Table size="small">
                    <TableHead sx={{ '& .MuiTableCell-head': { height: '20px' } }}>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Ticket ID</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Tax Year</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Service ID</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Status</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Assigned Agent</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Support</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ '& .MuiTableCell-head': { height: '20px' } }}>
                        {currentTickets.length > 0 ? (
                            currentTickets.map(ticket => (
                                <TableRow
                                    key={ticket.ticket_id}
                                    onClick={() => handleTicketClick(ticket.ticket_id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>
                                        <Button
                                            onClick={() => handleTicketClick(ticket.ticket_id)}
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            {ticket.ticket_id}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{ticket.service_year_value}</TableCell>
                                    <TableCell>{ticket.service_id}</TableCell>
                                    <TableCell>
                                        <Typography variant="body3" sx={{
                                            backgroundColor: '#ff4700',
                                            color: 'white',
                                            borderRadius: '16px',
                                            px: 1,
                                            py: 0.5,
                                            fontSize: '14px',
                                            top: '1px',
                                            fontWeight: 'bold'
                                        }}>
                                            {ticket.ticket_status || 'N/A'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{ticket.assignee_agent_name || 'N/A'}</TableCell>
                                    <TableCell>{ticket.assignee_agent_name || 'N/A'}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    No tickets available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
            />
        </>
    )}
</Box>

        </Box>
    );
};

export default AgentHomeView;
