import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';

const apiUrl = process.env.REACT_APP_API_URL;

const BusinessFormationForm = ({ userType, ticketData, setTicketData }) => {
    const [businessFormationDetails, setBusinessFormationDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isVerified, setIsVerified] = useState(ticketData?.organizer_verified);

    useEffect(() => {
        const fetchBusinessFormationDetails = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'business_formation_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.business_formation_details;
                if (details) {
                    setBusinessFormationDetails(JSON.parse(details));
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinessFormationDetails();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        try {
            const token = await getValidAccessToken();
            const response = await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'business_formation_details',
                    column_value: JSON.stringify(businessFormationDetails),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log('Form saved successfully:', response.data);
        } catch (error) {
            console.error('Error saving form:', error);
        }
    };

    const handleVerify = async () => {
        try {
            const token = await getValidAccessToken();
            const response = await axios.get(
                `${apiUrl}/verify-document/?ticket_id=${ticketData?.ticket_id}&column=organizer`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.data.success) {
                setIsVerified(true);
                setTicketData({
                    ...ticketData,
                    organizer_verified: true,
                    organizer_verified_by: 'Agent Name', // Adjust as needed
                });
            }
        } catch (error) {
            console.error('Error verifying document:', error);
        }
    };

    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        doc.text('Business Formation Details', 10, 10);
        Object.entries(businessFormationDetails || {}).forEach(([key, value], index) => {
            doc.text(`${key}: ${value}`, 10, 20 + index * 10);
        });
        doc.save('business_formation_details.pdf');
    };

    const renderFormFields = () => (
        <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
                {/* Line 1: Name */}
                <Grid item xs={4}>
                    <TextField
                        label="First Name"
                        value={businessFormationDetails?.first_name || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, first_name: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Middle Name"
                        value={businessFormationDetails?.middle_name || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, middle_name: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Last Name"
                        value={businessFormationDetails?.last_name || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, last_name: e.target.value })}
                        fullWidth
                    />
                </Grid>

                {/* Line 2: SSN */}
                <Grid item xs={12}>
                    <TextField
                        label="SSN"
                        value={businessFormationDetails?.ssn || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, ssn: e.target.value })}
                        fullWidth
                    />
                </Grid>

                {/* Line 3: Address */}
                <Grid item xs={6}>
                    <TextField
                        label="Address Line 1"
                        value={businessFormationDetails?.address_line1 || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, address_line1: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Address Line 2"
                        value={businessFormationDetails?.address_line2 || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, address_line2: e.target.value })}
                        fullWidth
                    />
                </Grid>

                {/* Line 4: City, State, Zip Code */}
                <Grid item xs={4}>
                    <TextField
                        label="City"
                        value={businessFormationDetails?.city || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, city: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="State"
                        value={businessFormationDetails?.state || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, state: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Zip Code"
                        value={businessFormationDetails?.zip_code || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, zip_code: e.target.value })}
                        fullWidth
                    />
                </Grid>

                {/* Line 5: Phone No */}
                <Grid item xs={6}>
                    <TextField
                        label="Phone No"
                        value={businessFormationDetails?.phone_no || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, phone_no: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Email"
                        value={businessFormationDetails?.email || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, email: e.target.value })}
                        fullWidth
                    />
                </Grid>

                {/* Line 6: Entity Type */}
                <Grid item xs={12}>
                    <RadioGroup
                        row
                        value={businessFormationDetails?.entity_type || ''}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, entity_type: e.target.value })}
                        disabled={userType === 'agent' || isVerified}
                    >
                        <FormControlLabel value="Limited Liability Company" control={<Radio />} label="Limited Liability Company" disabled={userType === 'agent' || isVerified} />
                        <FormControlLabel value="S-Corporation" control={<Radio />} label="S-Corporation" disabled={userType === 'agent' || isVerified} />
                        <FormControlLabel value="Corporation" control={<Radio />} label="Corporation" disabled={userType === 'agent' || isVerified} />
                        <FormControlLabel value="Partnership" control={<Radio />} label="Partnership" disabled={userType === 'agent' || isVerified} />
                        <FormControlLabel value="Not sure" control={<Radio />} label="Not sure" disabled={userType === 'agent' || isVerified} />
                    </RadioGroup>
                </Grid>

                {/* Line 7: Entity Names */}
                <Grid item xs={6}>
                    <TextField
                        label="First Choice Entity Name"
                        value={businessFormationDetails?.first_choice_entity_name || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, first_choice_entity_name: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Second Choice Entity Name"
                        value={businessFormationDetails?.second_choice_entity_name || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, second_choice_entity_name: e.target.value })}
                        fullWidth
                    />
                </Grid>

                {/* Message */}
                <Grid item xs={12}>
                    <TextareaAutosize
                        minRows={4}
                        placeholder="Message"
                        value={businessFormationDetails?.message || ''}
                        disabled={userType === 'agent' || isVerified}
                        onChange={(e) => setBusinessFormationDetails({ ...businessFormationDetails, message: e.target.value })}
                        style={{ width: '100%' }}
                    />
                </Grid>

                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    {userType === 'agent' && !isVerified && (
                        <Button variant="contained" color="primary" onClick={handleVerify} style={{ marginRight: '10px' }}>
                            Verify
                        </Button>
                    )}
                    {userType === 'agent' && isVerified && (
                        <Typography style={{ color: 'green', fontWeight: 'bold' }}>Verified</Typography>
                    )}
                </Grid>
            </Grid>
            <Box mt={2}>
                {userType === 'user' && (
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {businessFormationDetails ? 'Update' : 'Submit'}
                    </Button>
                )}
                {userType === 'agent' && (
                    <Button variant="contained" color="primary" onClick={handleDownloadPdf}>
                        Download as PDF
                    </Button>
                )}
            </Box>
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box>
            {userType === 'agent' ? (
                <>
                    {!businessFormationDetails ? (
                        <Typography>Details are not provided</Typography>
                    ) : (
                        <>
                            {renderFormFields()}
                        </>
                    )}
                </>
            ) : (
                renderFormFields()
            )}
        </Box>
    );
};

export default BusinessFormationForm;
