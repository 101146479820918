import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Grid, FormControl, FormLabel, TextField, Typography, Radio, RadioGroup, FormControlLabel, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';

const apiUrl = process.env.REACT_APP_API_URL;


const HomeOfficeExpenses = ({ userType, ticketData, setTicketData }) => {
    const [homeBusinessDetails, setHomeBusinessDetails] = useState({
        usedForBusiness: '',
        expenses: []
    });
    const [loading, setLoading] = useState(true);
    const [accordionOpen, setAccordionOpen] = useState(false);

    useEffect(() => {
        const fetchHomeBusinessDetails = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    '${apiUrl}/tax-organizer-details/',
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'home_expenses_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.home_expenses_details;
                if (details) {
                    setHomeBusinessDetails(JSON.parse(details));
                }
            } catch (error) {
                console.error('Error fetching home business details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchHomeBusinessDetails();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        try {
            const token = await getValidAccessToken();
            await axios.post(
                '${apiUrl}/update-taxorganizer-field/',
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'home_expenses_details',
                    column_value: JSON.stringify(homeBusinessDetails),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log('Form saved successfully');
        } catch (error) {
            console.error('Error saving form:', error);
        }
    };

    const handleAddExpense = () => {
        setHomeBusinessDetails({
            ...homeBusinessDetails,
            expenses: [...homeBusinessDetails.expenses, { squareFeet: '', totalSquareFeet: '', casualtyLosses: '', deductibleMortgageInterest: '', realEstateTaxes: '', rent: '', insurance: '', repairMaintenance: '', utilities: '', otherExpenses: '' }]
        });
    };

    const handleExpenseChange = (index, field, value) => {
        const updatedExpenses = [...homeBusinessDetails.expenses];
        updatedExpenses[index] = { ...updatedExpenses[index], [field]: value };
        setHomeBusinessDetails({ ...homeBusinessDetails, expenses: updatedExpenses });
    };

    const handleDeleteExpense = (index) => {
        const updatedExpenses = homeBusinessDetails.expenses.filter((_, i) => i !== index);
        setHomeBusinessDetails({ ...homeBusinessDetails, expenses: updatedExpenses });
    };

    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        doc.text('Home Office Expenses', 10, 10);
        doc.text(`Did the Taxpayer and spouse use their home for business in 2024? ${homeBusinessDetails.usedForBusiness}`, 10, 20);

        homeBusinessDetails.expenses.forEach((expense, index) => {
            doc.text(`Expense ${index + 1}:`, 10, 30 + (index * 10));
            Object.entries(expense).forEach(([key, value], subIndex) => {
                doc.text(`${key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}: ${value}`, 10, 40 + (index * 10) + (subIndex * 10));
            });
        });

        doc.save('home_office_expenses.pdf');
    };

    const renderExpensesTable = () => (
        <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleAddExpense} disabled={userType === 'agent'}>
                Add More Expenses
            </Button>
            {homeBusinessDetails.expenses.map((expense, index) => (
                <Accordion key={index} expanded={accordionOpen} onChange={() => setAccordionOpen(!accordionOpen)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Expense {index + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {Object.keys(expense).map((field) => (
                                <Grid item xs={12} sm={6} key={field}>
                                    <TextField
                                        label={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                        value={expense[field]}
                                        onChange={(e) => handleExpenseChange(index, field, e.target.value)}
                                        fullWidth
                                        disabled={userType === 'agent'}
                                    />
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Button variant="outlined" color="error" onClick={() => handleDeleteExpense(index)} disabled={userType === 'agent'}>
                                    Delete Expense
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Did the Taxpayer and spouse use their home for business in 2024?</FormLabel>
                        <RadioGroup
                            row
                            value={homeBusinessDetails.usedForBusiness}
                            name="homeBusinessUse"
                            onChange={(e) => setHomeBusinessDetails({ ...homeBusinessDetails, usedForBusiness: e.target.value })}
                            disabled={userType === 'agent'}
                        >
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                {homeBusinessDetails.usedForBusiness === 'Yes' && (
                    <Grid item xs={12}>
                        {renderExpensesTable()}
                    </Grid>
                )}

                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    {userType === 'user' && (
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            {homeBusinessDetails ? 'Update' : 'Submit'}
                        </Button>
                    )}
                    {userType === 'agent' && (
                        <Button variant="contained" color="primary" onClick={handleDownloadPdf}>
                            Download as PDF
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default HomeOfficeExpenses;
