import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth'; // Import the custom hook

const apiUrl = process.env.REACT_APP_API_URL;

const Signup = () => {
useAuth();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    reEnterPassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    referralCode: '',
  });

  //const [error, setError] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (formData.password !== formData.reEnterPassword) {
      setErrorMessage("Passwords don't match.");
      return;
    }
    

    try {
      const response = await axios.post(`${apiUrl}/signup/`, {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        first_name: formData.firstName,
        last_name: formData.lastName || '',
        phone_number: formData.phoneNumber || '',
        referral_code: formData.referralCode || '',
      });

      const { success, message } = response.data;

      if (success) {
        navigate('/login', { state: { message } });
      } else {
        setErrorMessage(message);
      }
    } catch (error) {
        console.error(error.response);
        setErrorMessage(error.response.data.message || 'An error occurred. Please try again.');

      //setError(error.response.data.message || 'Something went wrong. Please try again.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ mt: 1, mb: 1 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Sign Up
        </Typography>
        {errorMessage && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="dense"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="dense"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            type="email"
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="dense"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            type="password"
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Re-enter Password"
            variant="outlined"
            fullWidth
            margin="dense"
            name="reEnterPassword"
            value={formData.reEnterPassword}
            onChange={handleChange}
            required
            type="password"
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            margin="dense"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            margin="dense"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="dense"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            type="tel"
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Referral Code"
            variant="outlined"
            fullWidth
            margin="dense"
            name="referralCode"
            value={formData.referralCode}
            onChange={handleChange}
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            type="submit"
            sx={{ mt: 3 }}
          >
            Create Account
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Signup;
