import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import axios from 'axios';
import { getValidAccessToken } from '../../utils/tokenUtils';
import TicketData from '../TicketData';

const apiUrl = process.env.REACT_APP_API_URL;


const MyDocumentsTab = ({ ticketId, userType, ticketData }) => {
    const [files, setFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const [isDocumentVerified, setIsDocumentVerified] = useState(ticketData.document_verified);

    const fetchFiles = useCallback(async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/ticket-files/list/?ticket_id=${ticketId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                setFiles(response.data.data);
            } else {
                setErrorMessage('Unable to fetch files.');
            }
        } catch (error) {
            setErrorMessage('Error fetching files.');
            console.error('Error fetching files:', error);
        }
    }, [ticketId]);

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    const handleFileUpload = async (event) => {
        const token = await getValidAccessToken();
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('ticket_id', ticketId);
        formData.append('file_name', file.name);

        setUploading(true);

        try {
            const saveResponse = await axios.post(`${apiUrl}/ticket-files/`, formData, {
                headers: { 
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (saveResponse.data.success) {
                await fetchFiles();
            } else {
                setErrorMessage('Unable to save file details.');
            }
        } catch (error) {
            setErrorMessage('Error uploading file.');
            console.error('Error uploading file:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleVerify = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/verify-document/?ticket_id=${ticketId}&column=document`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                console.log('Ticket document verified successfully');
                setIsDocumentVerified(true); // Update the document verification status
            } else {
                setErrorMessage('Unable to verify document.');
            }
        } catch (error) {
            setErrorMessage('Error verifying document.');
            console.error('Error verifying document:', error);
        }
    };

    if (!ticketData) {
        return <div>Loading...</div>; // Or any loading indicator
    }

    return (
        <Box>
            {errorMessage && (
                <Typography variant="body2" color="error">{errorMessage}</Typography>
            )}

            <Paper 
                sx={{ 
                    p: 2, 
                    mt: 2, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 2 
                }}>

<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0 }}>

                
                <Box sx={{ display: 'flex',  alignItems: 'center', mb: 0 }}>
                    <Typography style={{paddingRight:'10px'}} variant="body2">Document Status:</Typography>
                    {isDocumentVerified ? (
                        <Button sx={{ fontWeight: 'bold'}} variant="contained"  color="secondary" disabled>
                        Verified
                    </Button>
                    ) : (userType === 'agent' ? (
                        <Button variant="contained" color="primary" onClick={handleVerify}>
                        Verify
                    </Button>
                    ) : (
                        <Typography variant="body2" color="red" >Not Verified</Typography>
                    ))}
                </Box>

                {userType !== 'agent' && (
    <Box sx={{ display: 'flex', justifyContent: 'right', mb: 0 }}>
        <input 
            type="file" 
            onChange={handleFileUpload} 
            style={{ display: 'none' }} 
            id="upload-file" 
        />
        <label htmlFor="upload-file" style={{ cursor: 'pointer' }}>
            <Box
                sx={{
                    border: '2px dashed #1976d2',
                    borderRadius: '8px',
                    padding: '30px',
                    width: '400px',
                    height: '100px',
                    textAlign: 'center',
                    backgroundColor: '#f9f9f9',
                    color: '#1976d2',
                    '&:hover': {
                        backgroundColor: '#e3f2fd',
                        borderColor: '#1565c0',
                    }
                }}
            >
                {uploading ? 'Uploading...' : 'Upload/Drop files here'}
            </Box>
        </label>
    </Box>
    
)}
</Box>

                {files.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="files table">
                            <TableHead>
                                <TableRow>
                                    <TableCell  sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>File ID</TableCell>
                                    <TableCell  sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem'  }}>Filename</TableCell>
                                    <TableCell  sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem'  }}>Uploaded On</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((file) => (
                                    <TableRow key={file.file_id}>
                                        <TableCell sx={{ fontSize: '0.875rem' }}>{file.file_id}</TableCell>
                                        <TableCell sx={{ fontSize: '0.875rem' }}>
                                            <Typography 
                                                variant="body2" 
                                                component="a" 
                                                href={`http://localhost:3000${file.file_path}${file.file_name}`} 
                                                target="_blank" 
                                                rel="noopener noreferrer">
                                                {file.file_name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '0.875rem' }}>
                                            {new Date(file.upload_date).toLocaleString()}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="body2">No files found for this ticket.</Typography>
                )}
            </Paper>
        </Box>
    );
};

export default MyDocumentsTab;