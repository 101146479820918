// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Signup from './components/Signup';
import Login from './components/Login';
import Home from './components/Home';

import Header from './components/Header';
import '@fontsource/catamaran'; // Defaults to weight 400

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import CreateTicket from './components/CreateTicket';
import TicketData from './components/TicketData';

//#ff4700 - orange
//#0e1422 - Blue
//#ffffff - White

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
     <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-ticket" element={<CreateTicket />} />
          <Route path="/ticket-data" element={<TicketData />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
